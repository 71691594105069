



























































import { Component, Vue } from "vue-property-decorator";
import { appName } from "@/env";
import { commitAddNotification } from "@/store/main/mutations";
import { dispatchResetPassword } from "@/store/main/actions";

@Component
export default class UserProfileEdit extends Vue {
  public appName = appName;
  public valid = true;
  public password1 = "";
  public password2 = "";

  public mounted() {
    this.checkToken();
  }

  public reset() {
    this.password1 = "";
    this.password2 = "";
    this.$validator.reset();
  }

  public cancel() {
    this.$router.push("/");
  }

  public checkToken() {
    const token = this.$router.currentRoute.query.token as string;
    if (!token) {
      commitAddNotification(this.$store, {
        content: "No token provided in the URL, start a new password recovery",
        color: "error",
      });
      this.$router.push("/recover-password");
    } else {
      return token;
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const token = this.checkToken();
      if (token) {
        await dispatchResetPassword(this.$store, {
          token,
          password: this.password1,
        });
        this.$router.push("/");
      }
    }
  }
}
